import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { graphql } from "gatsby";
import get from "lodash/get";
import Layout from "../components/layout";
import CookieBar from "../components/cookiebar";
import BackToTopButton from "../components/Generic/backToTop";
import LoadingAnimation from "../components/Generic/loadingAnimation";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Seo from "../components/seo";
import PageBanner from "../components/pageBanner";
import "../scss/pages/genericPages.scss";
import { getOptions } from "../helpers/genericPageHelper";

function ReferenceDetailPage(props) {
  const page = props.page
    ? props.page
    : get(props, "data.contentfulReferenceItem");
  const breakpoints = useBreakpoint();
  const options = getOptions(page.body);

  return (
    <Layout
      language={props.pageContext.language}
      noPageBanner={page.pageBanner ? "" : "true"}
    >
      <Seo meta={page.seo} />
      <PageBanner data={page.pageBanner} className="generic-page__banner" />
      <div className="generic-page__sections reference-detail">
        {documentToReactComponents(JSON.parse(page.body.raw), options)}
      </div>
      <CookieBar language={props.pageContext.language} />
      {!breakpoints.md && (
        <BackToTopButton language={props.pageContext.language} />
      )}
      <LoadingAnimation />
    </Layout>
  );
}

export default ReferenceDetailPage;

export const pageQuery = graphql`
  query ReferenceBySlug($slug: String!, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          language
          ns
          data
        }
      }
    }
    contentfulReferenceItem(
      slug: { eq: $slug }
      node_locale: { eq: $language }
    ) {
      ...ReferenceItemFragment
    }
  }
`;
